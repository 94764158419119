<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show"
    max-width="500px"
    max-height="800px"
    scrollable
    persistent
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-card background-color="#f2f2f2" v-else>
      <v-card-title
        class="py-0 pt-7"
        style="background-color: #7253cf; color: white"
      >
        <div class="d-flex px-1">
          <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
        </div>
        <div class="ml-1 dialogTitle-text">Intro Email</div>
        <v-spacer></v-spacer>
        <v-icon
          text
          large
          color="white"
          style="
            cursor: pointer;
            position: relative;
            right: -15px;
            bottom: 40px;
          "
          @click="toggleIntroEmailModal({ show: false })"
          >mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-10">
        <v-form >
          <v-row no-gutters>
            <v-col cols="12" class="pr-2">
              <v-menu
                ref="menu"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    prepend-inner-icon="mdi-calendar-range"
                     v-model="formatedStartDate"
                    label="Date"
                    v-bind="attrs"
                    readonly
                    color="#7253CF"
                    class="formFields"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  no-title
                    v-model="start_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
     
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="dialogAction-btnText text-capitalize px-7"
          height="45"
          color="#38227A"
          :loading="formLoading"
          @click="submitForm"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Axios from "@/api/BaseAxios";
import {
 API_CHECKLIST_INTRO_EMAIL
} from "@/constants/APIUrls";
export default {
  name: "IntroEmailModal",
  data() {
    return {
      menu1: false,
       start_date: "",
    };
  },
  computed: {
    ...mapGetters({
      getShow: "gameManagement/getIntroEmailShow",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleIntroEmailModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.introEmailModal.type;
    },
      gameID() {
      return this.$store.state.gameManagement.introEmailModal.gameID;
    },
    checklistID(){
     return this.$store.state.gameManagement.introEmailModal.checklistID;
     },
     formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleIntroEmailModal: "gameManagement/toggleIntroEmailModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
    },
    closeModal() {
    }, 
    submitForm() {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;
        self.toggleIntroEmailModal({ show: false });
        // self.$emit("reload");
       location.reload();
        this.showToast({
          message: "Successfully generate",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["checklist_item"] = this.checklistID;
      formData["game"] = this.gameID;
      formData["action_date"] = this.formatedStartDate;
        Axios.request_POST(
          API_CHECKLIST_INTRO_EMAIL,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 20px;
}
img.logo {
  margin-left: 20px;
  max-height: 80px;
}
.file-input-col {
  margin-left: 20px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
